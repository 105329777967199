/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
//import '../styles/reset.css'

/** @jsx jsx */
import { jsx } from 'theme-ui'
// import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Grid } from "theme-ui";
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <Grid gap={[1,2,3]} columns={['1fr', 'repeat(12,1fr)']}
    m={'0 auto'}
    px={[ 3, 3, 3, 5, 6]}
    py={[1, 2, 3]}
    >
      <Header author={data.site.siteMetadata.author} />
      <Box as="main" sx={{gridColumnStart:'span 12'}}>
        {children}
      </Box>
      <Footer author={data.site.siteMetadata.author}/>
    </Grid>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
