/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
// import React from "react"
import { Flex, Box, Grid } from "theme-ui"
import { AiFillBehanceSquare, AiFillLinkedin } from "react-icons/ai"

const Header = ({ author }) => {
  return (
    <header
      sx={{ gridColumnStart: "span 12" }}
      //sx={{position: 'fixed', width:'100%', zIndex:'10', backgroundColor:'white', overflow:'hidden'}}
    >
      <Grid columns={["1fr", "repeat(12,1fr)"]}>
        <Flex sx={{ alignItems: "baseline", gridColumnStart: "span 3" }}>
          <Link
            sx={{
              fontFamily: "title",
              fontWeight: "body",
              fontSize: 5,
              whiteSpace: "nowrap",
              display: ['none', 'none', 'none', 'block'],
            }}
            to={"/"}
          >
            {author}
          </Link>
          <Link
            sx={{
              fontFamily: "title",
              fontWeight: "body",
              fontSize: 5,
              whiteSpace: "nowrap",
              display: ['none', 'none', 'block', 'none'],
            }}
            to={"/"}
          >
            CMorejon
          </Link>
          <Link
            sx={{
              fontFamily: "title",
              fontWeight: "body",
              fontSize: 5,
              whiteSpace: "nowrap",
              display: ['block', 'block', 'none', 'none'],
            }}
            to={"/"}
          >
            CM
          </Link>
          <Box sx={{ alignSelf: "center", ml: "1", mt: "2" }}>
            <a
              href="https://www.linkedin.com/in/cristinamorejon/"
              target="_blank"
              rel="noreferrer noopener"
              id="linkedinIcon"
            >
              <AiFillLinkedin sx={{ size: "1.75em" }} />
            </a>
          </Box>
        </Flex>
        <Box sx={{ alignItems: "baseline", gridColumnStart: "4", mt: "3" }}>
          <Link
            activeClassName="naviactive"
            className="navi"
            to={"/"}
            variant="nav"
          >
            Portfolio
          </Link>
          {/* <Link
            sx={{ mx: "3" }}
            activeClassName="naviactive"
            className="navi"
            to={"/thoughts"}
            variant="nav"
          >
            Thoughts
          </Link> */}
          <Link
            sx={{ ml: "3" }}
            activeClassName="naviactive"
            className="navi"
            to={"/about"}
            variant="nav"
          >
            About
          </Link>
        </Box>
      </Grid>
    </header>
  )
}

export default Header
